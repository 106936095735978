<template>
    <div>
        <el-drawer
            :title="name+'座位号管理'"
            :visible.sync="drawerShow"
            :wrapperClosable="false"
            :destroy-on-close="true"
            size="auto"
            @close="close"
        >
            <div class="drawer-content">
				<div class="temp-head clearfix">
				    <div class="font16 MB20">1、请先下载售票座位号导入表，在表格中填写每张售票对应的座位号</div>
				    <el-button type="primary" @click="download(1)" size="small" :loading="downLoading">下载座位号导入表</el-button>
					
				</div>                            
                <div class="temp-head clearfix MT50">
                    <div class="font16 MB20">2、填写好售票座位号导入表后，在下方上传。上传成功后会在用户票夹中显示座位号。</div>
						<div>
							<el-upload
								action
								:http-request="uploadExcel"
								:auto-upload="false"
								ref="upload"
								accept=".xls, .xlsx"
								:show-file-list="true"
								:before-upload="beforeUpload"
								:file-list="fileList"
								:on-change="onChange"
								:on-remove="onRemove"
								:disabled="uploadLoading"
								style="width:100%">
								<el-button size="small" style="width:100%" type="primary" :loading="uploadLoading">{{uploadLoading ? '上传中' : '上传座位号导入表'}}</el-button>
							</el-upload>
						</div>
						<div class="download color-theme MT20" v-if="siteFileUrl && data.siteFileUrl" style="cursor: pointer;"  @click="download(2)"  >{{name}}座位号导入表.xlsx</div>
						<div v-if="MsgText"  class="MT20" >
							<el-alert show-icon type="error" v-if="MsgText" :closable="false">
							    <div slot="title">上传失败：{{MsgText}}</div>
							</el-alert>
						</div>
						
                </div>				
                <div class="MT50">                    
					<div class="font16">请选择票品的类型</div>				
					<el-radio-group v-model="buildTime">
						<div class="MT20"><el-radio :label="0">演出开始前1天展示</el-radio></div>
						<div class="MT20"><el-radio :label="1">演出开始当天0点展示</el-radio></div>
						<div class="MT20"><el-radio :label="2">演出开始前6个小时展示</el-radio></div>
						<div class="MT20"><el-radio :label="3">创建后立即生成展示</el-radio></div>
					</el-radio-group>
					<small><div class="color-danger MT20">说明：演出开始前半个小时以前才可以导入座位号，只有导入下载的标准格式座位表才会生效；<br>上传新的座位号表用户票夹会覆盖原来的座位号</div></small>
                </div>                
            </div>
            <div class="drawer-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="onSubmit" :loading="btnLoading" :disabled="canSubmit"
                    >提交</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import {downloadFileTmp,uploadFileTmp} from '@/assets/js/commonApi.js';

export default {
   
    components:{},
    data(){
        return{
            drawerShow:false,
            btnLoading:false,
            downLoading:false,
            uploadLoading:false,
            canSubmit:false,
			fileList:[],
			id:'',
			name:'',
			buildTime:0,
			data:{},
			siteFileUrl:false,
			filePath:'',
			fileFullPath:'',
			MsgText:''
        }
    },
    methods:{        
		show(data) {
			
			this.data = data
		    this.id = data.sessionId;
		    this.name = data.sessionName
		    this.drawerShow = true;
			if( this.data.siteFileUrl ){
				this.siteFileUrl = true
			}
			if( this.data.buildTime ){
				this.buildTime = this.data.buildTime
			}else{
				this.buildTime = 0
			}
		},
        close(){
            this.fileList = [];
            this.$refs.upload.clearFiles();
            this.drawerShow = false;
			this.canSubmit = false;
			this.btnLoading = false;
			this.data = {}
			this.MsgText = ''
        },
		async downloadExcel(siteFileUrl){
		    const _url = siteFileUrl;		    
			await downloadFileTmp(_url, this.name + '座位号导入表.' + this.$utils.getFileType(_url));			
		   
		},
		
        //下载模板
        download(type){
			let filePath;
			if( type == 1 ){
				this.downLoading = true;
			}			
			this.$request({
				url: '/activity/site/template',
				method: 'POST',
				params: {sessionId:this.id } ,
			}).then(res => {
				const { state, msg, result } = res.data;
				if( state == 1 && result ){
					filePath = result.url;					
					downloadFileTmp(filePath,`座位号.`+this.$utils.getFileType(filePath));
					if( type == 1 ){
						this.downLoading = false;
					}
					
				}else{
					if( type == 1 ){
						this.downLoading = false;
					}
					
					return this.$message.error(msg || '下载失败');
				}
			}).catch(() => {
				this.$message.error('请求错误');
			}).finally(() => {
				
				if( type == 1 ){
					this.downLoading = false;
				}
			});
        },
		
        onSubmit(){			
			if( this.siteFileUrl ){
				this.$request({
				    url:'/activity/site/save',
				    method: 'post',
				    data:{buildTime:this.buildTime,sessionId: this.id}
				})
				    .then(res => {
				        const{state,msg,result} = res.data;
				        if (state == 1) {
							this.$message.success('操作成功');
				            this.onSuccess(); 
							this.$emit('onUpdate');
							this.MsgText = ''
				        }else{
				            this.$message.error(msg || '操作失败');
				            this.canSubmit = false;
							this.btnLoading = false;
							this.MsgText = msg
				        }
				    })
				    .catch(err => {
				        this.canSubmit = false;
						this.btnLoading = false;
				        this.$message({message: err.message, type: 'error'});
				    })
				    
			}else{
				this.$refs.upload.submit();
			}
            
        },
        async uploadExcel(req){
			
            this.btnLoading = true;
            this.uploadLoading = true;
            const formData = new FormData();
            formData.append('file', req.file);

            let filePath;
			let fileFullPath;
            const res = await uploadFileTmp(formData).catch(err => {
                this.$message({message: err.message, type: 'error'});
            }).finally(() => {
                this.uploadLoading = false;
            });
            const{state,msg,result} = res.data;
            if (state == 1 && result) {
				
                filePath = result.url;
				fileFullPath = result.urlFull
				
				this.filePath = result.url
				this.fileFullPath = result.urlFull
            }else{
                this.$message.error(msg || '上传失败');
            }

            this.$request({
                url:'/activity/site/save',
                method: 'post',
                data:{filePath:filePath,fileFullPath:fileFullPath,buildTime:this.buildTime,sessionId: this.id}
            })
                .then(res => {
                    const{state,msg,result} = res.data;
                    if (state == 1) {
						this.$message.success('操作成功');
                        this.onSuccess();
						this.$emit('onUpdate');
						this.MsgText = ''
                    }else{
                        this.$message.error(msg || '上传失败');
						this.MsgText = msg
                        this.canSubmit = true;
						this.btnLoading = false;
                    }
                })
                .catch(err => {
                    this.canSubmit = false;
					this.btnLoading = false;
                    this.$message({message: err.message, type: 'error'});
                })
               
        },
        beforeUpload(file){
            const type = this.$utils.getFileType(file.name)
            const isType = type === 'xls' || type === 'xlsx';
            if (!isType) {
                this.$message.error('仅支持xls、xlsx文件格式,请重新选择上传文件!');
            }
            return isType;
        },
        onSuccess(){
            this.$emit('onUpdate');
            this.close();
        },
        onChange(file){
            if( file ){
				this.siteFileUrl = false				
				this.MsgText = ''
                this.fileList = [{...file}]
                this.canSubmit = false;
            }else{
                this.canSubmit = true;
            }
        },
        onRemove(){
            this.canSubmit = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.temp-head{
    line-height: 28px;
}
.temp-table{
    margin-top: 15px;
}
.temp-tips{
    border-bottom: 1px #EBEEF5 solid;
    padding-bottom: 15px;
    .el-icon-info{
        font-size: 16px;
    }
    .color-regular{
        margin-top: 8px;
        font-size: 13px;
        line-height: 20px;
    }
}
.font16{
	font-size:16px;
	color:#000
}
.MT50{
	margin-top: 50px;
}
.MB20{
	margin-bottom: 20px;
}
.download{
	display:block
}
</style>