<template>
    <div>
        <div class="page-top-box">
            <div class="page-title-box">
                <div class="go-back">
                    <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>|
                </div>
                {{ $route.meta.title }}
            </div>
        </div>
        <!-- <activity-tab name="2"></activity-tab> -->
        <div v-loading="dataLoading">
            <div class="page-group-box">
                <div class="page-group-title" v-if="data">{{data.activityName}} 座位号管理</div>
                <el-row class="info-box">
                    <el-col :xl="24">
                        <el-row :gutter="40">                            
							<el-col :span="24">
							    <small class="color-info fr"  @click="agreementVisible = true">创建排队序号时默认同意<span class="color-theme">《秀动座位号功能使用协议》</span></small>
							</el-col>
                        </el-row>
                    </el-col>
                </el-row>               
            </div>
            <div v-if="data && data.dataList.length > 0" class="page-group-box MT10">
                <div class="page-group-title">座位号管理列表</div>
                <el-table
                    :data="data.dataList"
                    style="width: 100%"
                    size="small"
                    header-row-class-name="page-list-table"
                    class="MT20"
                >
                    <el-table-column prop="sessionName" label="场次"></el-table-column>
                    <el-table-column prop="sellNum" label="售票数"></el-table-column>
            		<el-table-column prop="store" label="库存"></el-table-column>
            		<el-table-column prop="type" label="座位号">
            			<template slot-scope="scope">
            			    <span v-if="scope.row.type == 0">未设置</span>
            			    <span v-if="scope.row.type == 1">通排</span>
            			    <span v-if="scope.row.type == 2">分组</span>
            			    <span v-if="scope.row.type == 3">已设置</span>
            			</template>
            		</el-table-column>
            		<el-table-column prop="startTime" label="开演时间"></el-table-column>
            		<el-table-column  label="操作">
            			<template slot-scope="scope">
            				<el-button
            				    type="text"
            				    size="small"
            					@click="$refs.BatchSeatData.show(scope.row)"							
            				    >管理座位号</el-button
            				>
            			</template>
            			
            		</el-table-column>
                </el-table>
            </div>
            <div class="page-group-box MT10">
                <el-button @click="goBack">返回</el-button>
            </div>
        </div>
        <BatchSeatData ref="BatchSeatData" :id="id"  @onUpdate="getData"></BatchSeatData>
		<el-dialog
		    title="座位号功能使用协议"
		    :visible.sync="agreementVisible"
		    width="500px"
		    center>
		    <div class="dialog-content">
		        <div>您好，欢迎使用秀动提供的排队序号功能，在使用座位号功能前请仔细阅读并理解《秀动座位号功能使用协议》，以下简称协议。当您为场次创建协议时我们将视为您已阅读并同意协议。</div>
				      <div class="Atitle">第一条：功能概念</div>
				      <p>每场演出开始前30分钟及以上，可以在秀动经营平台设置对应的座位号。座位号在创建后，会根据选择的显示时间在用户的秀动app票夹中显示。创建座位号需要手动在下载的【座位号导入表】填写售票id对应的座位号然后上传。</p>				       
				     <div class="Atitle">第二条：功能适用的演出</div>
				      <p>2.1 演出开始前30分钟及以上创建</p>
				      <p>2.2 需要对售票进行座位号分配的演出</p>				       
				      <div class="Atitle">第三条：座位号生成后可以覆盖</div>
				      <p>座位号生成后，在演出开始30分钟之前都可以再次上传【座位号导入表】覆盖之前提交的座位号</p>				       
				       <div class="Atitle">第四条：座位号的解释权</div>
				      <p>创建的座位号号解释权归主办所有，秀动不对您在经营平台创建的座位号负有解释权</p>				       
				       <div class="Atitle">第五： 关于座位号带来的用户纠纷和争议</div>
				       <p>线下由排座位号带来的用户纠纷和争议，将由设置座位号的主办自己承担和处理。</p>
		    </div>
		    <span slot="footer" class="dialog-footer">		        
		        <el-button type="primary"  @click="agreementVisible = false">确 定</el-button>
		    </span>
		</el-dialog>
    </div>
</template>

<script>

import BatchSeatData from './components/batchSeatData';
export default {
    props: {
        id: {
            type: [Number, String],
            default: null,
        },
		name: {
		    type:String,
		    default:'',
		}
    },
    components:{BatchSeatData},
    data(){
        return{
            redirect: this.$route.query.redirect || '/manage/activity/list',
            dataLoading:false,
            data:null,
            downLoading: false,
			agreementVisible:false
        }
    },
    mounted(){
       this.getData()
    },
    methods:{
        //返回
        goBack() {
            if(this.redirect){
                this.$router.push({
                    path: this.redirect,
                });
            }else{
                this.$router.go(-1);
            }
        },
        //获取数据
        getData(){
			
            this.dataLoading = true;
            this.$request({
                url: "/activity/site/list",
                params: { activityId: parseInt(this.id) },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.data = result;
                    } else {
                        this.$message.error(msg || "获取信息失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取信息失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
       
    }
   
}
</script>

<style lang="scss" scoped>
.info-box{
    .el-col{
        .el-col{
            font-size: 16px;
            margin-top: 15px;
            label{
                display: inline-block;
                width: 50%;
            }
        }
    }
}
// .sessions-box{
//     margin-top: 30px;
//     .card-box{
//         margin-top: 10px;
//         border-radius: 4px;
//         border: 1px solid $--border-color-base;
//         background: rgba(235, 238, 245, 0.2);
//         padding: 5px 20px 20px 20px;
//     }
// }
.page-group-title{
    small{
        font-size: 13px;
    }
}
.Atitle{
	font-size:16px;
	color:#000;
	margin-top: 20px;
	margin-bottom: 8px;
}
</style>